import { createBrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import { lazy } from "react";
import Homepage from "./Pages/Homepage.tsx";
import Login from "./Pages/Login.tsx";
import VerifyEmail from "./Pages/VerifyEmail.tsx";
import Imprint from "./Pages/Imprint.tsx";
import Privacy from "./Pages/Privacy.tsx";
import RegistrationComplete from "./Pages/RegistrationComplete.tsx";
import { ErrorElement } from "./components/ErrorElement.tsx";

const Registration = lazy(() => import("./Pages/Registration.tsx"));
const CandidateDashboard = lazy(
  () => import("./Pages/Candidate/CandidateDashboard.tsx"),
);
const ParticipationInApplicationProcess = lazy(
  () => import("./Pages/Candidate/ParticipationInApplicationProcess.tsx"),
);
const OpenApplicationProcesses = lazy(
  () => import("./Pages/Candidate/OpenApplicationProcesses.tsx"),
);
const CommitteeDashboard = lazy(
  () => import("./Pages/Committee/CommitteeDashboard.tsx"),
);
const ParticipationsInFieldOfStudyList = lazy(
  () => import("./Pages/Committee/ParticipationsInFieldOfStudyList.tsx"),
);
const Participation = lazy(() => import("./Pages/Committee/Participation.tsx"));

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "registrieren",
        element: <Registration />,
      },
      {
        path: "registriert",
        element: <RegistrationComplete />,
      },
      {
        path: "email-verifizieren",
        element: <VerifyEmail />,
      },
      {
        path: "impressum",
        element: <Imprint />,
      },
      {
        path: "datenschutz",
        element: <Privacy />,
      },
      {
        path: "bewerbung",
        element: <CandidateDashboard />,
      },
      {
        path: "bewerbung/bewerbung/:participationInApplicationProcessId",
        element: <ParticipationInApplicationProcess />,
      },
      {
        path: "bewerbung/offene-bewerbungen",
        element: <OpenApplicationProcesses />,
      },
      {
        path: "kommission",
        element: <CommitteeDashboard />,
      },
      {
        path: "kommission/:applicationProcessId/:fieldOfStudyId",
        element: <ParticipationsInFieldOfStudyList />,
      },
      {
        path: "kommission/:applicationProcessId/:fieldOfStudyId/:participationId",
        element: <Participation />,
      },
    ],
  },
]);
