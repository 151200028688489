import { Container, Text, Title } from "@mantine/core";

export const Privacy = () => {
  return (
    <Container my="lg">
      <Title mb="xl">Datenschutz</Title>
      <Title order={2} mb="lg">
        Kontakt Verantwortliche
      </Title>
      <Text mb="lg">
        <strong>
          Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer
          nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
          datenschutzrechtlicher Bestimmungen ist:
        </strong>
      </Text>
      <Text>
        Hochschule Bielefeld
        <br />
        Fachbereich Gestaltung
        <br />
        Dekan Prof. Dirk Fütterer
        <br />
        +49 (0)521 106 7616
        <br />
        gestaltung@hsbi.de
        <br />
        Lampingstraße 3<br />
        33615 Bielefeld
        <br />
        Deutschland
      </Text>
      <Title order={2} my="lg">
        Kontakt Datenschutzbeauftragte
      </Title>
      <Text mb="lg">
        <strong>Bei Fragen zum Datenschutz bitte wenden an:</strong>
      </Text>
      <Text>
        Datenschutzbeauftragte der Hochschule Bielefeld
        <br />
        Interaktion 1,
        <br />
        33619 Bielefeld
        <br />
        datenschutzbeauftragte@hsbi.de
      </Text>

      <Title order={2} my="lg">
        Allgemeines zur Datenverarbeitung
      </Title>

      <ul>
        <li>
          <strong>Umfang der Verarbeitung personenbezogener Daten</strong>
          <br />
          Wir erheben und verwenden personenbezogene Daten unserer Nutzer
          grundsätzlich nur, soweit dies zur Bereitstellung einer
          funktionsfähigen Website sowie unserer Inhalte und Leistungen
          erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten
          unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers.
          Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige
          Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich
          ist und die Verarbeitung der Daten durch gesetzliche Vorschriften
          gestattet ist.
        </li>
        <li>
          <strong>
            Rechtsgrundlage für die Verarbeitung personenbezogener Daten
          </strong>
          <br />
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
          Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit.
          a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für die
          Verarbeitung personenbezogener Daten. Bei der Verarbeitung von
          personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
          Vertragspartei die betroffene Person ist, erforderlich ist, dient Art.
          6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für
          Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen
          erforderlich sind. Soweit eine Verarbeitung personenbezogener Daten
          zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der
          unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
          Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der
          betroffenen Person oder einer anderen natürlichen Person eine
          Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
          Abs. 1 lit. d DSGVO als Rechtsgrundlage. Ist die Verarbeitung zur
          Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
          Dritten erforderlich und überwiegen die Interessen, Grundrechte und
          Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so
          dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die
          Verarbeitung.
        </li>
        <li>
          <strong>Datenlöschung und Speicherdauer</strong>
          <br />
          Die personenbezogenen Daten der betroffenen Person werden gelöscht
          oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
          Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den
          europäischen oder nationalen Gesetzgeber in unionsrechtlichen
          Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
          Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
          Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
          Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
          Erforderlichkeit zur weiteren Speicherung der Daten für einen
          Vertragsabschluss oder eine Vertragserfüllung besteht.
        </li>
      </ul>
    </Container>
  );
};

export default Privacy;
