import { Button, Text, Container, Title, Loader } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { verifyEmail } from "../openapi/api-client.ts";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconLogin2 } from "@tabler/icons-react";
import { useForwardIfLoggedIn } from "../hooks/useForwardIfLoggedIn.ts";

export const VerifyEmail = () => {
  useForwardIfLoggedIn("/bewerbung");

  const verifyEmailMutation = useMutation({
    mutationFn: verifyEmail,
  });

  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    verifyEmailMutation.mutate(
      {
        emailVerificationToken: searchParams.get("token") ?? "",
      },
      {
        onSuccess: () => {
          setVerified(true);
        },
      },
    );
  }, []);

  return (
    <Container my="lg">
      <Title mb="lg">E-Mail-Adresse verifizieren</Title>

      {verifyEmailMutation.isPending && (
        <Text>
          <Loader /> E-Mail-Adresse wird verifiziert
        </Text>
      )}

      {verified && (
        <>
          <Text mb="lg">
            Dein E-Mail-Adresse wurde verifiziert. Du kannst dich jetzt
            einloggen und bewerben.
          </Text>
          <Button
            color="blue"
            size="lg"
            component={Link}
            to="/login"
            leftSection={<IconLogin2 />}
          >
            Login
          </Button>
        </>
      )}
    </Container>
  );
};

export default VerifyEmail;
