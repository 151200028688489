import { MantineColorsTuple } from "@mantine/core";

export const hsbiColors: Record<string, MantineColorsTuple> = {
  rot: [
    "#faeff1",
    "#eedcde",
    "#e1b5ba",
    "#d48b93",
    "#ca6973",
    "#c3535e",
    "#c14854",
    "#ab3944",
    "#99313c",
    "#862732", // original (not 100%)
  ],
  bronze: [
    "#fff2e8",
    "#f3e4db",
    "#dfc9bc",
    "#cbab98", // original (not 100%)
    "#bb917a",
    "#b18166",
    "#ad795b",
    "#98674b",
    "#885b40",
    "#794d33",
  ],
  sand: [
    "#faf6eb",
    "#F3F1ED",
    "#d7d2c3", // original
    "#c1b9a3",
    "#ada489",
    "#a29777",
    "#9d906d",
    "#887d5b",
    "#7a6f4f",
    "#6a5f3e",
  ],
  gruen: [
    "#f1f9ea",
    "#e5eddf",
    "#ccd7c1",
    "#b2c1a1",
    "#9aae86", // original (not 100%)
    "#8ba374",
    "#849d6b",
    "#708859",
    "#637a4d",
    "#53693d",
  ],
  grau: [
    "#fef2f5",
    "#eae6e7",
    "#cdcdcd", // original (not 100%)
    "#b2b2b2",
    "#9a9a9a",
    "#8b8b8b",
    "#848484",
    "#717171",
    "#676465",
    "#5e5457",
  ],
  blau: [
    "#e3faff",
    "#d7edf6",
    "#b4d8e6",
    "#8dc1d6",
    "#6daec8", // original (not 100%)
    "#58a3bf",
    "#4a9dbc",
    "#3788a7",
    "#297a96",
    "#0a6a86",
  ],
};
