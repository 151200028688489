import { Anchor, Container, Text, Title } from "@mantine/core";

export const Imprint = () => {
  return (
    <Container my="lg">
      <Title mb="xl">Impressum</Title>
      <Text mb="lg">Angaben gemäß §5 TM</Text>
      <Text mb="lg">
        <strong>Inhaltlich verantwortlich ist</strong>
        <br />
        Hochschule Bielefeld
        <br />
        Fachbereich Gestaltung
        <br />
        Dekan Prof. Dirk Fütterer
        <br />
        +49 (0)521 106 7616
        <br />
        gestaltung@hsbi.de
        <br />
        Lampingstraße 3
        <br />
        33615 Bielefeld
        <br />
        Deutschland
      </Text>
      <Text>
        <strong>Impressum der Hochschule Bielefeld</strong>
        <br />
        <Anchor href="https://hsbi.de/impressum" target="_blank">
          hsbi.de/impressum
        </Anchor>
      </Text>
    </Container>
  );
};

export default Imprint;
