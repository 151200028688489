import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createTheme,
  CSSVariablesResolver,
  MantineProvider,
  rem,
} from "@mantine/core";
import "@mantine/core/styles.css";
import { hsbiColors } from "./layout/colors.ts";
import "./layout/fonts.css";
import "./layout/styles.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes.tsx";
import { UserData } from "./UserContext.tsx";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000,
    },
  },
});

const theme = createTheme({
  fontFamily: "Lausanne, Arial, sans-serif",
  colors: {
    ...hsbiColors,
    red: hsbiColors.rot,
    blue: hsbiColors.blau,
    green: hsbiColors.gruen,
  },
  headings: {
    textWrap: "balance",
    sizes: {},
  },
  autoContrast: true,
  defaultRadius: 0,
  respectReducedMotion: true,
  fontSizes: {
    xs: rem(14),
    sm: rem(16),
    md: rem(18),
    lg: rem(20),
    xl: rem(24),
  },
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {},
  light: {
    "--mantine-color-anchor": theme.colors.blue[9],
    "--mantine-color-blue-filled": theme.colors.blue[8],
    "--mantine-color-blue-filled-hover": theme.colors.blue[9],
  },
  dark: {},
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme} cssVariablesResolver={resolver}>
        <UserData>
          <RouterProvider router={routes} />
          <ReactQueryDevtools initialIsOpen={false} />
        </UserData>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
