import {
  Anchor,
  Image,
  Grid,
  Stack,
  Text,
  Title,
  Container,
  TypographyStylesProvider,
  List,
} from "@mantine/core";
import { Link } from "react-router-dom";
import DMXVisual from "../layout/images/mappenportal-visual-dmx.jpg";
import FachbereichVisual from "../layout/images/mappenportal-visual-fb1.jpg";
import FUBVisual from "../layout/images/mappenportal-visual-fub.jpg";
import KDVisual from "../layout/images/mappenportal-visual-kd.jpg";
import MVisual from "../layout/images/mappenportal-visual-m.jpg";

export const Homepage = () => {
  return (
    <TypographyStylesProvider>
      <Container fluid>
        <Grid my="lg" gutter="lg">
          <Grid.Col span={5} visibleFrom="md">
            <Stack align="flex-end" justify="flex-start" gap="xl">
              <div>
                <Image src={FachbereichVisual} alt="" h={250} />
              </div>
              <div>
                <Image src={MVisual} alt="" h={350} />
              </div>
              <div>
                <Image src={DMXVisual} alt="" h={250} />
              </div>
              <div>
                <Image src={KDVisual} alt="" h={150} />
              </div>
              <div>
                <Image src={FUBVisual} alt="" h={350} />
              </div>
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ md: 5 }}>
            <Title mb="xl">
              Anmeldung zur Eignungsprüfung FB Gestaltung Bielefeld
            </Title>
            <Text>Liebe Bewerber.innen,</Text>
            <Text>
              wir freuen uns, dass Ihr Euch für ein Studium bei uns am
              Fachbereich Gestaltung in Bielefeld interessiert. Wir bieten Euch
              zwei Termine für die Eignungsprüfung zum Bachelor-Studiengang
              Gestaltung B.A. an. Die Anmeldefrist dafür ist immer 1. Januar
              oder 1. Juni.
            </Text>
            <Text>
              Die Bewerbung im Mappenportal gilt als Anmeldung, egal ob eine
              analoge oder digitale Mappe abgegeben wird. Das Mappenportal ist
              immer zwei Monate vorher für Euch geöffnet.
            </Text>
            <Text>
              <Anchor component={Link} to="/registrieren">
                Registrieren und für die Eignungsprüfung anmelden
              </Anchor>
            </Text>
            <Title order={2}>Die vier Studienrichtungen</Title>
            <Text>
              Erster Schritt ist die Auswahl der Studienrichtung. Unentschieden?
              Ihr könnt Euch auch für zwei Studienrichtungen anmelden, dann gebt
              jeweils eine passende Mappe ab.
            </Text>
            <Text>Informiert Euch hier über die Studieninhalte:</Text>
            <List>
              <List.Item>
                <Anchor
                  aria-label="Studieninhalte Digital Media and Experiment"
                  href="https://www.hsbi.de/gestaltung/dmx/studienrichtung"
                  target="_blank"
                >
                  Digital Media and Experiment
                </Anchor>
              </List.Item>
              <List.Item>
                <Anchor
                  aria-label="Studieninhalte Fotografie und Bildmedien"
                  href="https://www.hsbi.de/gestaltung/fub/studienrichtung"
                  target="_blank"
                >
                  Fotografie und Bildmedien
                </Anchor>
              </List.Item>
              <List.Item>
                <Anchor
                  aria-label="Studieninhalte Kommunikationsdesign"
                  href="https://www.hsbi.de/gestaltung/kd/studienrichtung"
                  target="_blank"
                >
                  Kommunikationsdesign
                </Anchor>
              </List.Item>
              <List.Item>
                <Anchor
                  aria-label="Studieninhalte Mode"
                  href="https://www.hsbi.de/gestaltung/mode/studienrichtung"
                  target="_blank"
                >
                  Mode
                </Anchor>
              </List.Item>
            </List>
            <Title order={2}>Die Mappe</Title>
            <Text>
              Die Mappe dient der Vorauswahl. Ihr könnt sie entweder digital im
              Mappenportal abgeben, oder analog im Fachbereich. Sie wird dann
              von den Kommissionen gesichtet und Ihr erfahrt am Mittwoch vor der
              Eignungsprüfung per Mail, ob Ihr eingeladen werdet.
            </Text>
            <Text>Hier sind die Vorgaben für die Mappe:</Text>

            <List>
              <List.Item>
                <Anchor
                  aria-label="Mappenvorgaben Digital Media and Experiment"
                  href="https://www.hsbi.de/gestaltung/dmx/mappe"
                  target="_blank"
                >
                  Digital Media and Experiment
                </Anchor>
              </List.Item>
              <List.Item>
                <Anchor
                  aria-label="Mappenvorgaben Fotografie und Bildmedien"
                  href="https://www.hsbi.de/gestaltung/fub/mappe"
                  target="_blank"
                >
                  Fotografie und Bildmedien
                </Anchor>
              </List.Item>
              <List.Item>
                <Anchor
                  aria-label="Mappenvorgaben Kommunikationsdesign"
                  href="https://www.hsbi.de/gestaltung/kd/mappe"
                  target="_blank"
                >
                  Kommunikationsdesign
                </Anchor>
              </List.Item>
              <List.Item>
                <Anchor
                  aria-label="Mappenvorgaben Mode"
                  href="https://www.hsbi.de/gestaltung/mode/mappe"
                  target="_blank"
                >
                  Mode
                </Anchor>
              </List.Item>
            </List>

            <Title order={2}>Ablauf Eignungsprüfung und Bewerbung</Title>

            <Text>
              Auf unserer Info Page seht Ihr alle Details zum Ablauf der
              Eignungsprüfung bei uns und der späteren Bewerbung an der
              Hochschule Bielefeld.
            </Text>

            <Text>
              <Anchor href="https://www.hsbi.de/gestaltung/studieninteressierte/ba">
                Info Page Eignungsprüfung und Bewerbung
              </Anchor>
            </Text>
          </Grid.Col>
        </Grid>
      </Container>
    </TypographyStylesProvider>
  );
};

export default Homepage;
