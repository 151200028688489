import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useIsLoggedIn } from "./useIsLoggedIn.ts";

export const useForwardIfLoggedIn = (to: string) => {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn !== undefined && isLoggedIn) {
      navigate(to);
    }
  }, [isLoggedIn]);
};
