import classes from "./App.module.css";
import {
  Anchor,
  AppShell,
  Burger,
  Button,
  Container,
  Group,
  Image,
  Stack,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import HSBILogo from "./layout/logos/hsbi/hsbi-logo-without-slogan.svg";
import { IconLogin2, IconLogout2 } from "@tabler/icons-react";
import {
  Link,
  NavLink,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Suspense, useContext, useEffect } from "react";
import { UserContext } from "./UserContext.tsx";
import { useIsLoggedIn } from "./hooks/useIsLoggedIn.ts";
import { CenteredLoader } from "./components/CenteredLoader.tsx";
import { useHasRole } from "./hooks/useHasRole.ts";

export const App = () => {
  const [opened, { toggle }] = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const userIdContext = useContext(UserContext);
  const loggedIn = useIsLoggedIn();
  const isCandidate = useHasRole("candidate");
  const isCommittee = useHasRole("committee");

  // automatically close navbar
  useEffect(() => {
    if (opened) {
      toggle();
    }
  }, [location]);

  return (
    <>
      <ScrollRestoration />
      <AppShell
        withBorder={false}
        header={{ height: 90 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { desktop: true, mobile: !opened },
        }}
      >
        <AppShell.Header>
          <Container h="100%">
            <Group h="100%">
              <Group justify="space-between" style={{ flex: 1 }}>
                <Link to="/">
                  <Image
                    src={HSBILogo}
                    h={30}
                    alt="Logo: HSBI"
                    title="Zur Startseite"
                  />
                </Link>
                <Group ml="xl" gap="0" visibleFrom="sm">
                  {!loggedIn && (
                    <Anchor
                      component={NavLink}
                      to="/registrieren"
                      className={classes.desktopNavitem}
                    >
                      REGISTRIEREN
                    </Anchor>
                  )}
                  {isCandidate && (
                    <Anchor
                      component={NavLink}
                      to="/bewerbung"
                      className={classes.desktopNavitem}
                    >
                      MEINE BEWERBUNG
                    </Anchor>
                  )}
                  {isCommittee && (
                    <Anchor
                      component={NavLink}
                      to="/kommission"
                      className={classes.desktopNavitem}
                    >
                      BEWERBUNGEN
                    </Anchor>
                  )}
                  {!loggedIn ? (
                    <Button
                      component={Link}
                      size="md"
                      ml="xl"
                      to="/login"
                      color="blue"
                      leftSection={<IconLogin2 />}
                    >
                      LOGIN
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      ml="xl"
                      size="md"
                      onClick={() => {
                        userIdContext.setUserData("", []);
                        navigate("/");
                      }}
                      color="red"
                      leftSection={<IconLogout2 />}
                    >
                      LOGOUT
                    </Button>
                  )}
                </Group>
              </Group>
              <Burger
                opened={opened}
                onClick={toggle}
                hiddenFrom="sm"
                size="sm"
                aria-label="Menü-Sichtbarkeit umschalten"
              />
            </Group>
          </Container>
        </AppShell.Header>

        <AppShell.Navbar p="md">
          <Stack>
            {!loggedIn && (
              <Anchor
                component={NavLink}
                to="/registrieren"
                className={classes.mobileNavitem}
              >
                REGISTRIEREN
              </Anchor>
            )}
            {isCandidate && (
              <Anchor
                component={NavLink}
                to="/bewerbung"
                className={classes.mobileNavitem}
              >
                MEINE BEWERBUNG
              </Anchor>
            )}
            {isCommittee && (
              <Anchor
                component={NavLink}
                to="/kommission"
                className={classes.mobileNavitem}
              >
                BEWERBUNGEN
              </Anchor>
            )}
            <div>
              {!loggedIn ? (
                <Button
                  size="md"
                  component={Link}
                  to="/login"
                  color="blue"
                  leftSection={<IconLogin2 />}
                >
                  LOGIN
                </Button>
              ) : (
                <Button
                  size="md"
                  variant="outline"
                  onClick={() => {
                    userIdContext.setUserData("", []);
                    navigate("/");
                  }}
                  color="red"
                  leftSection={<IconLogout2 />}
                >
                  LOGOUT
                </Button>
              )}
            </div>
          </Stack>
        </AppShell.Navbar>

        <AppShell.Main>
          <Suspense fallback={<CenteredLoader />}>
            <Outlet />
          </Suspense>
          <Container mt="75" mb="xl" ta="right">
            <Anchor component={Link} to="/impressum">
              IMPRESSUM
            </Anchor>{" "}
            |{" "}
            <Anchor component={Link} to="/datenschutz">
              DATENSCHUTZ
            </Anchor>
          </Container>
        </AppShell.Main>
      </AppShell>
    </>
  );
};

export default App;
