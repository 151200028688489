import {
  Container,
  Title,
  Text,
  TypographyStylesProvider,
} from "@mantine/core";
import { useForwardIfLoggedIn } from "../hooks/useForwardIfLoggedIn.ts";

export const RegistrationComplete = () => {
  useForwardIfLoggedIn("/bewerbung");

  return (
    <Container my="lg">
      <TypographyStylesProvider>
        <Title mb="lg">Du bist jetzt registriert</Title>
        <Text>
          Bitte checke deine Mail App, wir haben dir eine Mail geschickt, in der
          du deine Registrierung bestätigen musst.
        </Text>
        <Text>
          Anschließend kannst du dich im Portal einloggen. Dort kannst du deine
          Studienrichtung auswählen und deine Mappe hochladen.
        </Text>
        <Text>Diese Seite kannst du schließen.</Text>
      </TypographyStylesProvider>
    </Container>
  );
};

export default RegistrationComplete;
