import { createContext } from "react";
import { useLocalStorage } from "@mantine/hooks";

export const UserContext = createContext<{
  userData: {
    userId: string;
    roles: string[];
  };
  setUserData: (userId: string, roles: string[]) => void;
}>({
  userData: {
    userId: "",
    roles: [],
  },
  setUserData: () => {},
});

export const UserData = (props: { children: React.ReactNode }) => {
  const [userId, setUserId] = useLocalStorage<string>({
    key: "userId",
    defaultValue: "",
  });

  const [roles, setRoles] = useLocalStorage<string[]>({
    key: "roles",
    defaultValue: [],
  });

  const setUserData = (userId: string, roles: string[]) => {
    setUserId(userId);
    setRoles(roles);
  };

  return (
    <UserContext.Provider value={{ userData: { userId, roles }, setUserData }}>
      {props.children}
    </UserContext.Provider>
  );
};
