import { Container, Title, Text, Code } from "@mantine/core";
import { useRouteError } from "react-router-dom";

export const ErrorElement = () => {
  const error = useRouteError();

  return (
    <Container my="lg">
      <Title mb="xl">Ein Fehler ist aufgetreten</Title>

      {error instanceof Error ? (
        <>
          <Text mb="xl">
            Bitte nimm Kontakt mit der HSBI auf und teile uns die folgenden
            Informationen mit:
          </Text>

          <Code block>{error.stack}</Code>
        </>
      ) : (
        <Text>Bitte lade die Seite neu und führe die Aktion erneut aus.</Text>
      )}
    </Container>
  );
};
