import { useContext } from "react";
import { UserContext } from "../UserContext.tsx";
import { useIsLoggedIn } from "./useIsLoggedIn.ts";

export const useHasRole = (role: string): boolean | undefined => {
  const userContext = useContext(UserContext);
  const loggedIn = useIsLoggedIn();

  if (!loggedIn) {
    return loggedIn;
  }

  return userContext.userData.roles.includes(role);
};
